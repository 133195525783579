import { useRuntimeConfig } from '#app'
const { useCustomFetch } = useFetchBase()

// request(REQ) Api  --------------------------------
const base = '/api/auth'
const modules = '/trustedDomains'
const path = base + modules

const getTrustedDomainList = path // 獲取信任網站列表

const addTrustedDomain = path // 新增信任網站

const editTrustedDomain = path + '/{0}' // 修改信任網站

const deleteTrustedDomain = path + '/{0}' // 刪除信任網站

// request(REQ) Function  --------------------------------

/**
 * 獲取信任網站列表
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
export const getTrustedDomainListFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(getTrustedDomainList, params), {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 新增信任網站
 * @param {Object} formData 表單提交
 * @param {Object} callback 回調函數
 * @returns
 */
export const addTrustedDomainFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + addTrustedDomain, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 修改信任網站
 * @param {String} id id
 * @param {Object} formData 表單提交
 * @param {Object} callback 回調函數
 * @returns
 */
export const editTrustedDomainFn = (id, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(editTrustedDomain, id)
  return useCustomFetch(api, {
    method: 'PUT',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 刪除信任網站
 * @param {String} id id
 * @param {Object} callback 回調函數
 * @returns
 */
export const deleteTrustedDomainFn = (id, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(deleteTrustedDomain, id)
  return useCustomFetch(api, {
    method: 'DELETE',
    onResponse: callback
  })
}
